import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import Table from '../../../Objects/Table';
import { FaRedo } from 'react-icons/fa';
import { useHistory, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';
import UserSelect from './UserSelect';
import ItemSelect from './ItemSelect';
import { Decimal } from 'decimal.js';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [lock, setLock] = useState(false);
	const [reload, setReload] = useState(0);
	const [data_item, setDataItem] = useState([]);
	const [data_user, setDataUser] = useState([]);
	const [item, setItem] = useState({});
	const [cart, setCart] = useState([]);
	const [alert, setAlert] = useState([]);
	const [showUser, setShowUser] = useState([false]);
	const [showItem, setShowItem] = useState([false]);
	const [shipping, setShipping] = useState(0);
	const [freeShippingPrice, setFreeShippingPrice] = useState(0);

	useEffect(() => {
		
		const getData = async () => {
			
			const getItem = async () => {
				const ref = await API.Item.Scan();
				setDataItem(ref.items);
			};
			
			const getUser = async () => {
				const ref = await API.User.Scan();
				setDataUser(ref.items);
			};
			
			const getShipping = async () => {
				const ref = await API.Param.Scan();
				const shipping = Number(ref?.items?.find((v) => v.name === 'shipping')?.value ?? 0);
				const free_shipping_price = Number(ref?.items?.find((v) => v.name === 'free_shipping_price')?.value ?? 0);
				setShipping(shipping);
				setFreeShippingPrice(free_shipping_price);
			}

			await getItem();
			await getUser();
			await getShipping();

			//表示開始
			setLoad(false);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSubmit = async (event) => {
	
		const createNewData = async (data) => {

			const newData = [];
			
			for(const item of data){
				
				const price = Number(item.price ?? 0);
				const piece = Number(item.piece ?? 0);
				const sub_price = new Decimal(price).times(piece).toNumber();
				const unit_price = Number(item.price ?? 0);
				
				const params ={
					item_id: item.item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					minimum_unit : item.minimum_unit,
					category1_name : item.category1_name,
					category1_code : item.category1_code,
					category2_name : item.category2_name,
					category2_code : item.category2_code,
					genre_name : item.genre_name,
					genre_code : item.genre_code,
					catalog_no : item.catalog_no,
					keyword : item.keyword,
					sort : item.sort,
					boxed_num : item.boxed_num,
					url : item.url,
					pre_order_num : item.pre_order_num,
					stock_flag : item.stock_flag,
					price: price,
					piece: piece,
					sub_price: sub_price,
					unit_price: unit_price,
				};
				newData.push(params);
			}
			return(newData);
		};
		
		const getTotalPrice = async (data) => {

			let total_price = new Decimal(0);
			let total_unit_price = new Decimal(0);

			for(const item of data){
				const sub_price = new Decimal(item.price).times(item.piece);
				const sub_unit_price = new Decimal(item.unit_price).times(item.piece);
			
				total_price = total_price.plus(sub_price);
				total_unit_price = total_unit_price.plus(sub_unit_price);
			}

			let new_shipping = new Decimal(0);
				
			if (total_unit_price.lessThan(freeShippingPrice)) {
				new_shipping =  new Decimal(shipping);
			}
			total_price = total_price.plus(new_shipping);

			return({
				total_price : total_price.toNumber(),
				new_shipping : new_shipping.toNumber(),
			});

		};
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//カートの中身チェック
			if(cart.length === 0){
				throw new Error('カートの中身がありません。');
			}
			
			//受注商品データの確認と取得
			const newData = await createNewData(cart);
			
			//合計金額の取得
			const {total_price, new_shipping} = await getTotalPrice(newData);
			
			//更新処理
			const params ={
				user_id : item.user_id,
				email : item.email,
				user_no : formObj.user_no.value,
				user_name : formObj.user_name.value,
				zip : formObj.zip.value,
				addr : formObj.addr.value,
				company : formObj.company.value,
				person : formObj.person.value,
				tel : formObj.tel.value,
				mobile : formObj.mobile.value,
				fax : formObj.fax.value,
				items : newData,
				shipping : new_shipping,
				total_price: total_price,
				note : formObj.note.value,
				status: '注文の登録完了',
			};
			//console.log(params);
			
			//更新
			const ref = await API.Order.Put(params);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + JSON.stringify(ref.error) + ')');
			}
			
			throw new Error('追加しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (item_id) => {
		
		//Formをロック
		setLock(true);
		
		try {
			
			let newItems = [];
			
			for(const item of cart){
				if(item_id !== item.item_id){
					newItems.push(item);
				}
			}
			
			setCart(newItems);
			
			window.location.href='#' + item_id;
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handlePieceChange = async (item_id, piece) => {
		
		//Formをロック
		setLock(true);
		
		try {
		
			let newItems = [];
			
			for(const item of cart){
				if(item_id === item.item_id){
					item.piece = piece > 0 ? Number(piece) : 1;
				}
				newItems.push(item);
			}
			
			setCart(newItems);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleUserShow = () => {
		setShowUser([true]);
	};
	
	const handleItemShow = () => {
		setShowItem([true]);
	};
	
	const handleUserClick = (row) => {

		//Formをロック
		setLock(true);
		
		try {
			const params = {
				user_id : row.user_id,
				email : row.email,
				user_no : row.user_no,
				user_name : row.user_name,
				zip : row.zip,
				addr : row.addr,
				company : row.company,
				person : row.person,
				tel : row.tel,
				fax : row.fax,
				mobile : row.mobile,
			};

			setItem(params);

		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);

		setShowUser([false]);
	};
	
	const handleItemClick = (row) => {

		//Formをロック
		setLock(true);
		
		try {
		
			let newItems = [];
			
			let flag = false;
			
			for(const item of cart){
				if(row.item_id === item.item_id){
					item.piece = Number(item.piece + 1);
					flag = true;
				}
				newItems.push(item);
			}
			
			if(flag === false){
				row.piece = 1;
				newItems.push(row);
			}
			
			setCart(newItems);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);

		setShowItem([false]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='追加しました。'){
			window.location.reload();
		}
	};
	
	const getTotalPrice = () => {
		let total_price = 0;
		for(const x of cart){
			const sub_price = Number(x.price) * Number(x.piece);
			total_price = total_price + sub_price;
		}
		let new_shipping = 0;
		if(total_price < freeShippingPrice){
			new_shipping = shipping;
		}
		total_price += new_shipping;
		return(Number(total_price ?? 0).toLocaleString());
	};

	const getShipping = () => {
		let total_price = 0;
		for(const x of cart){
			const sub_price = Number(x.price) * Number(x.piece);
			total_price = total_price + sub_price;
		}
		let new_shipping = 0;
		if(total_price < freeShippingPrice){
			new_shipping = shipping;
		}
		return(Number(new_shipping ?? 0).toLocaleString());
	};

	const formatItemLink = (cell, row) => {
		return (
			<div>
				<a href={'/item/' + row.item_id}>{cell}</a>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatPiece = (cell, row) => {
		const sub_price = Number(row.price) * Number(row.piece);
		return (
			<div className="text-right">
				<a name={'#' + row.item_id} href={'#' + row.item_id}>
					{Number(cell ?? 0).toLocaleString()} 個
				</a>
				　[ <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell + 1))} disabled={lock}>＋</a> | <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell - 1))} disabled={lock}>－</a> ]
				　
				{Number(sub_price ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatDelete = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
					削除
				</Button>
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			formatter: formatItemLink,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			formatter: formatItemLink,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			formatter: formatItemLink,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'gousu',
			text: '号数全角',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'piece',
			text: '個数／合計金額',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy2',
			text: '削除',
			sort: false,
			headerClasses: 'text-center',
			formatter: formatDelete,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				
				<ItemSelect data={data_item} show={showItem} onSelect={handleItemClick} />
				<UserSelect data={data_user} show={showUser} onSelect={handleUserClick} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/order/">受注情報</Breadcrumb.Item>
					<Breadcrumb.Item active>詳細</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormButton label={'ユーザの選択'} name={'item_id'} value={item.user_id ? item.user_id : '追加'} onClick={handleUserShow} required={true} disabled={lock} />
					<FormText label={'Eメール'} name={'email'} type={'email'} value={item.email} required={true} readOnly={true} disabled={lock} />
					<FormText label={'得意先コード'} name={'user_no'} value={item.user_no} required={true} disabled={lock} />
					<FormText label={'お名前'} name={'user_name'} value={item.user_name} required={true} disabled={lock} />
					<FormText label={'郵便番号'} name={'zip'} value={item.zip} disabled={lock} />
					<FormText label={'住所'} name={'addr'} value={item.addr} disabled={lock} />
					<FormText label={'会社名'} name={'company'} value={item.company} disabled={lock} />
					<FormText label={'担当者名'} name={'person'} value={item.person} disabled={lock} />
					<FormText label={'電話番号'} name={'tel'} type={'tel'} value={item.tel} disabled={lock} />
					<FormText label={'携帯電話'} name={'mobile'} type={'tel'} value={item.mobile} disabled={lock} />
					<FormText label={'FAX番号'} name={'fax'} type={'tel'} value={item.fax} disabled={lock} />
					<hr/>
					<FormButton label={'商品の追加'} name={'item_id'} value={'追加'} onClick={handleItemShow} disabled={lock} />
					<Row>
						<Col sm={12}>
							<Table keyField={'item_id'} data={cart} columns={columns} />
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col sm={12}>
							<table className="table  table-bordered">
								<tbody>
								<tr>
									<td>合計点数</td>
									<td className="text-right">{ Number(cart?.length ?? 0).toLocaleString() } 点</td>
								</tr>
								<tr>
									<td>送料</td>
									<td className="text-right">{ getShipping() } 円</td>
								</tr>
								<tr>
									<td>合計金額</td>
									<td className="text-right">{ getTotalPrice() } 円</td>
								</tr>
								</tbody>
							</table>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col sm={6}>
							<p><big>連絡欄</big></p>
						</Col>
					</Row>
					<textarea name="note" className="form-control" defaultValue={''}>
					</textarea>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >追加</Button>
								　
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
