import { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Breadcrumb,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import Encoding from 'encoding-japanese';
import CSVUpload from '../../../@onocomm-system/include/CSVUpload';


const Default = () => {

	const history = useHistory();
	const { section } = useParams();
	const [load, setLoad] = useState(true);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [column_data, setColumnData] = useState([]);
	
	useEffect(() => {
	
		//CSVのカラム情報を取得
		const getColumn = (section) => {
			return(CSVUpload[section]);
		};
		
		//CSVのカラム情報を取得
		const column = getColumn(section);
		
		//データ保存
		setColumnData(column);
		
		//ストレージ
		const storage = sessionStorage;
		
		//データ削除
		storage.removeItem('csv_data');
		storage.removeItem('csv_column_data');
		storage.removeItem('csv_select_ids');
		storage.removeItem('csv_final_check');

		//表示開始
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleSubmit = async (event) => {
		
		//ファイルオブジェクトからテキストデータに読み出し変換
		const createCSVText = async (file) => {
		
			//ファイルの読み込み
			const readFile = async (file) => {
				
				const result= await new Promise((resolve, reject) => {
					
					const reader = new FileReader();
					
					reader.onload = (event) => {
					    resolve(event.target.result);
					}
					reader.readAsArrayBuffer(file);
					
				});
				return(result);
			};
			
			//ファイルの読み込み
			const buffer = await readFile(file);
			//console.log(buffer);
			
			//8ビット配列化
			const codes = new Uint8Array(buffer);
			//console.log(codes);
			
			//エンコードの判定
			const encoding = Encoding.detect(codes);
			//console.log(encoding);
			
			//UNICODEへの変換
			const csvText = Encoding.convert(codes, {to: 'UNICODE', from: encoding, type: 'string' });
			//console.log(csvText);
			
			return(csvText);
		};
		
		//CSVデータの作成
		const createCSVData = async (csvText) => {
		
			//CSVヘッダーの作成
			const createHeader = async (csvText) => {
				
				//BOM削除
				if((csvText.split(''))[0].charCodeAt() === 65279){
					csvText = csvText.replace( /^.{1}/, '');
				}
				//console.log(data);
				
				//改行を元に配列化
				const lineArray = csvText.replace(/\r/g, '').split('\n');
				//console.log(data);

				//ヘッダー情報
				const headers = [];
				
				//1行目をヘッダー情報として取り扱い定義情報が存在するか確認
				for(const header of lineArray[0].split(',')){
					//定義情報との確認
					if(column_data.some(x => x.dataField === header) === false){
						throw new Error('CSV定義情報と適合しない識別IDの指定がありました。(識別ID：' + header + ')');
					}
					//トリム
					headers.push(header.trim());
				}
				
				//定義情報の項目すべてがあるかチェック
				for(const column of column_data){
					//定義情報とヘッダー情報の確認
					if(headers.some(header => header === column.dataField) === false){
						throw new Error('次の識別IDが見つかりません。(識別ID：' + column.dataField + ')');
					}
				}
				return(headers);
			};
			
			//ヘッダー情報の作成
			const headers = await createHeader(csvText);
			
			//返答用変数
			const csvData = [];
			
			//改行を元に配列化
			const lineArray = csvText.replace(/\r/g, '').split('\n');
			
			//行判定
			let i = 0;
			
			//1行ずつ処理
			for(const lineText of lineArray){
				
				//件数インクリメント
				i++;

				//1件目はヘッダー情報としてスキップ
				if(i === 1) { continue; }
				
				//行が空の場合はスキップ
				if(!lineText.trim()){ continue; }
				
				//配列化
				const columnArray = lineText.split(',');
				//console.log(columnArray);

				//整形用変数
				const lineObj = {};
				
				//ヘッダー情報からオブジェクトを作成する
				let j = 0;
				for(const header of headers){
					
					//定義情報を取得
					const define = (column_data.filter(x => x.dataField === header))[0];
					
					//列の取り出し
					const value = columnArray[j] ? columnArray[j].replace( /^"/, '').replace( /"$/, '').trim() : '';
					
					//必須項目の確認
					if(define.csvRequired && !value){
						throw new Error('次の識別IDは必須項目となります。(識別ID：' + header + ', 行数：' + i + ', 内容："' + value + '")');
					}
					
					//数値の確認
					if(define.csvType === '数値' && !Number.isFinite(Number(value))){
						throw new Error('次の識別IDは数値項目となります。(識別ID：' + header + ', 行数：' + i + ', 内容："' + value + '")');
					}
					
					//データ行
					lineObj[header] = value;
					
					//列数インクリメント
					j++;
				}

				//ハッシュの中身を配列に保存
				csvData.push(lineObj);
			}
			//console.log(csvData);
			
			return(csvData);
		};
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			
			//ファイルオブジェクト
			const file = event.target.files[0];
			//console.log(file);
			
			//ファイルが選択されなかった場合
			if(!file){ return(0); }
			
			//const size = file.size;
			//console.log(file.size.toLocaleString());
			
			//ファイルの読み込み
			const csvText = await createCSVText(file);
			
			//CSVデータの作成
			const csvData = await createCSVData(csvText);

			//JSON形式のテキストデータ
			const csvDataString = JSON.stringify(csvData);

			//ファイルサイズの確認
			if(csvDataString.length > 4194304){
				throw new Error('ファイルサイズが4MBを超えています。(' + csvDataString.length.toLocaleString() + 'Bytes)');
			}
			
			//ストレージ
			const storage = sessionStorage;

			//データ登録
			storage.setItem('csv_data', csvDataString);
			storage.setItem('csv_column_data', JSON.stringify(column_data));

			//ページ遷移
			window.location.href = '/admin/csv-upload/' + section + '/check/';
		}
		catch(err){
			setAlert([err.message]);
		}
		
		//フォームリセット
		document.form.file_select.value='';
		
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		//window.location.reload();
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(!section || (column_data?.length ?? 0) === 0){
		return (
			<ModalAlert data={['CSV種別が不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/csv-upload/">CSVアップロード</Breadcrumb.Item>
					<Breadcrumb.Item active>CSVファイルの選択</Breadcrumb.Item>
				</Breadcrumb>


				<Row>
					<Col sm={6}>
						<p><big>CSVファイルの選択</big></p>
					</Col>
				</Row>
				<hr/>
				<p>
					1．CSVフォーマットを確認の上、CSVファイルを作成してください。<br/>
					※1行目は必ずヘッダー行として識別IDを記載してください。<br/>
					※二重引用符「"」または単一引用符「'」の囲みには対応しません。<br/>
					※1度にアップロード出来るのはファイルサイズ4MBytesまでとなります。<br/>
					※1つめの識別IDがキーとなります。データベースに同じキーがあるデータは更新されます。<br/>
					※データ更新の際、項目が空文字の場合は空文字として上書きされますのでご注意ください。<br/>
				</p>
				<table border="1">
					<thead>
						<tr>
							<th>識別ID</th>
							<th>名称</th>
							<th>必須</th>
							<th>種別</th>
							<th>備考</th>
						</tr>
					</thead>
					<tbody>
						{column_data?.map(x => <tr key={x.dataField}><td>{x.dataField}</td><td>{x.text}</td><td>{x.csvRequired ? '〇' : ''}</td><td>{x.csvType}</td><td>{x.csvMemo}</td></tr>)}
					</tbody>
				</table>
				<hr/>
				<p>2．CSVファイルの選択を行ってください。</p>
				<Form name="form">
					<Row style={{ marginTop: 10 }}>
						<Col sm={6}>
							<label htmlFor="csv_upload" className="btn btn-dark btn-block">
								CSV入力
								<Form.Control type="file" name={'file_select'} accept=".csv" disabled={lock} onChange={handleSubmit} id="csv_upload" style={{display:'none'}}/>
						    </label>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col>
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
