import { useState, useEffect  } from 'react';
import { Auth } from 'aws-amplify';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, withRouter } from 'react-router-dom';
import { FormText } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [item, setItem] = useState({});
	const [email, setEmail] = useState('');
	
	useEffect(() => {
	
		const getData = async () => {

			//ログインユーザ名の取得
			const email = (await Auth.currentAuthenticatedUser()).attributes.email;
			
			//DynamoDB取得
			const ref = await API.User.Current();
			
			//console.log(ref);
			
			//データ取得
			setItem(ref.item);
			setEmail(email);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleUpdate = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//登録済みかどうか
			if(item.user_id){
				//更新処理
				const params ={
					email : email,
					representative_email : formObj.representative_email.value,
					//user_no : formObj.user_no.value,
					user_name : formObj.user_name.value,
					zip : formObj.zip.value,
					addr : formObj.addr.value,
					company : formObj.company.value,
					person : formObj.person.value,
					tel : formObj.tel.value,
					mobile : formObj.mobile.value,
					fax : formObj.fax.value,
				};
				console.log(params);
				
				//更新
				const ref = await API.User.Update(params);
				console.log(ref);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。(' + JSON.stringify(ref.error) + ')');
				}
				
			}
			else{
				//新規登録処理
				const params ={
					email : email,
					representative_email : formObj.representative_email.value,
					//user_no : formObj.user_no.value,
					user_name : formObj.user_name.value,
					zip : formObj.zip.value,
					addr : formObj.addr.value,
					company : formObj.company.value,
					person : formObj.person.value,
					tel : formObj.tel.value,
					mobile : formObj.mobile.value,
					fax : formObj.fax.value,
				};
				//console.log(params);
				
				//更新
				const ref = await API.User.Put(params);
				console.log(ref);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。(' + JSON.stringify(ref.error) + ')');
				}

			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(!item.user_id){
			window.location.reload();
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form name="form" onSubmit={handleUpdate}>

					<Row>
						<Col sm={6}>
							<p><big>お客様の詳細情報</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'Eメール'} name={'email'} type={'email'} value={email} readOnly={true} />
					<FormText label={'代表者Eメール'} name={'representative_email'} type={'email'} value={item.representative_email} />
					{/*<FormText label={'得意先コード'} name={'user_no'} value={item.user_no} readOnly={true} />*/}
					<FormText label={'お名前'} name={'user_name'} value={item.user_name} required={true} />
					<FormText label={'郵便番号'} name={'zip'} value={item.zip} />
					<FormText label={'住所'} name={'addr'} value={item.addr} />
					<FormText label={'会社名'} name={'company'} value={item.company} />
					<FormText label={'担当者名'} name={'person'} value={item.person} />
					<FormText label={'電話番号'} name={'tel'} type={'tel'} value={item.tel} />
					<FormText label={'携帯電話'} name={'mobile'} type={'tel'} value={item.mobile} />
					<FormText label={'FAX番号'} name={'fax'} type={'tel'} value={item.fax} />
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >登録</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
