import { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Breadcrumb,
	Badge,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import Table from '../../../Objects/Table';
import { ModalAlert } from '../../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { section } = useParams();
	const [load, setLoad] = useState(true);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [view, setView] = useState([]);
	const [csv_data, setCsvData] = useState([]);
	const [column_data, setColumnData] = useState([]);
	const [selectIDs, setSelectIDs] = useState([]);
	
	useEffect(() => {
	
		//ストレージ
		const storage = sessionStorage;
		
		//CSVデータ
		const csvData = storage.getItem('csv_data') ? JSON.parse(storage.getItem('csv_data')) : [];

		//Columnデータ
		const columnData = storage.getItem('csv_column_data') ? JSON.parse(storage.getItem('csv_column_data')) : [];
		
		//セレクトID削除
		storage.removeItem('csv_select_ids');

		//最終チェックフラグ削除
		storage.removeItem('csv_final_check');
		
		//debug
		//console.log(csvData);
		//console.log(columnData);
		
		//保存
		setView(csvData);
		setCsvData(csvData);
		setColumnData(columnData);
		
		//表示開始
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleSubmit = async (event) => {
		
		//CSVデータの確認
		const checkCSVData = async (csvData) => {
			
			//CSVヘッダーの確認
			const checkHeader = async (headers, item) => {
				
				//定義情報の項目すべてがあるかチェック
				for(const column of column_data){
					//定義情報とヘッダー情報の確認
					if(headers.some(header => header === column.dataField) === false){
						throw new Error('次の識別IDが見つかりません。(識別ID：' + column.dataField + ', 対象データ:"' + JSON.stringify(item) + '")');
					}
				}
			};
			
			//1行ずつ処理
			for(const item of csvData){
				
				//ヘッダー情報の取得
				const headers = Object.keys(item);
				
				//ヘッダー情報の確認
				await checkHeader(headers, item);
				
				for(const header of headers){
					
					//定義情報を取得
					const define = (column_data.filter(x => x.dataField === header))[0];

					//定義情報との確認
					if(Object.keys(define).length === 0){
						throw new Error('CSV定義情報と適合しない識別IDの指定がありました。(識別ID：' + header + ', 対象データ:"' + JSON.stringify(item) + '")');
					}
					
					//内容の取得
					const value = item[header];
					
					//必須項目の確認
					if(define.csvRequired && !value){
						throw new Error('次の識別IDは必須項目となります。(識別ID：' + header + ', 対象データ:"' + JSON.stringify(item) + '")');
					}
					
					//数値の確認
					if(define.csvType === '数値' && !Number.isFinite(Number(value))){
						throw new Error('次の識別IDは数値項目となります。(識別ID：' + header + ', 対象データ:"' + JSON.stringify(item) + '")');
					}
				}
			}
		};
			

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			//CSVデータの確認
			await checkCSVData(csv_data);
			
			//ストレージ
			const storage = sessionStorage;
			
			//データ登録
			storage.setItem('csv_data', JSON.stringify(csv_data));
			storage.setItem('csv_select_ids', JSON.stringify(selectIDs));
			
			
			//ページ遷移
			window.location.href = '/admin/csv-upload/' + section + '/final-check/';
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};

	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = csv_data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleClose = () => {
		setAlert([]);
		//window.location.reload();
	};
	
	//テーブル上で変更された値の変更
	const afterSaveCell = (oldValue, newValue, row, column) => {
		let i = 0;
		for(const item of csv_data){
			if(item[column_data[0].dataField] === row[column_data[0].dataField]){
				item[column.dataField] = newValue;
				csv_data[i] = item;
			}
			i++;
		}
	}
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(!section || column_data.length === 0){
		return (
			<ModalAlert data={['CSV種別が不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else if(csv_data.length === 0){
		return (
			<ModalAlert data={['CSVデータが不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/csv-upload/">CSVアップロード</Breadcrumb.Item>
					<Breadcrumb.Item href={'/admin/csv-upload/' + section + '/'}>CSVファイルの選択</Breadcrumb.Item>
					<Breadcrumb.Item active>アップロード内容の確認</Breadcrumb.Item>
				</Breadcrumb>


				<Row>
					<Col sm={6}>
						<p><big>アップロード内容の確認</big></p>
					</Col>
				</Row>
				<hr/>
				<p>
					※検索はデータ確認のためのものとなります。<br/>
					※検索結果にかかわらずアップロードは{csv_data.length.toLocaleString()}件、もしくはチェックされたデータが対象となります。<br/>
					※テーブルのセル上で編集されたデータは有効となります。<br/>
					※セル編集完了時にはリターンキーを押下しないと、編集は有効になりません<br/>
				</p>
				<Form onSubmit={handleSearch}>
					<Row style={{ marginTop: 20 }}>
						<Col sm={2} className="text-center">
							<Badge pill variant="secondary">
								<h6>　{ selectIDs.length ? selectIDs.length.toLocaleString() : csv_data.length ? csv_data.length.toLocaleString() : '0' }　</h6>
							</Badge>　
						</Col>
						<Col sm={5}>
							<Form.Group controlId="search_key">
							        <Form.Control name="search_key" type="text" placeholder="Search" />
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Button variant="dark" type="submit" block="true" >Search</Button>
						</Col>
					</Row>
				</Form>
				<Row style={{ marginTop: 20 }}>
					<Col sm={12}>
						<Table keyField={column_data[0].dataField} data={view} columns={column_data} checkbox={true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} cellEdit={ { mode: 'click', afterSaveCell: afterSaveCell } } />
					</Col>
				</Row>
				<hr/>
				<Form onSubmit={handleSubmit}>
					<Row style={{ marginTop: 10 }}>
						<Col>
							<Button variant="dark" type="submit" block="true" >最終確認</Button>
							　
							<Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
