import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
	Modal,
} from 'react-bootstrap';
import { FaRedo, FaStar, FaRegStar } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { item_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [modal, setModal] = useState([]);
	const [item, setItem] = useState({});
	const [wish_list, setWishList] = useState({});
	
	useEffect(() => {
	
		const getData = async () => {
			
			const getItem = async (item_id) => {
				const ref = await API.Item.Get(item_id);
				//console.log(ref);
				setItem(ref.item);
			};

			const getWishList = async (item_id) => {
				const ref = await API.WishList.Get(item_id);
				//console.log(ref);
				setWishList(ref.item);
			};
			
			await getItem(item_id);
			await getWishList(item_id);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleWishList = async (event) => {
		
		const putWishList = async (params) => {
			
			//追加
			const ref = await API.WishList.Put(params);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
		};

		const deleteWishList = async (item_id) => {
			
			//削除
			const ref = await API.WishList.Delete(item_id);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
		};

		//Formをロック
		setLock(true);
		
		try {

			const params ={
				item_id : item.item_id,
				product_no : item.product_no,
				product_name : item.product_name,
				gousu : item.gousu,
				price : item.price ? Number(item.price) : 0,
				unit_price : item.unit_price ? Number(item.unit_price) : 0,
				minimum_unit : item.minimum_unit,
				category1_name : item.category1_name,
				category1_code : item.category1_code,
				category2_name : item.category2_name,
				category2_code : item.category2_code,
				genre_name : item.genre_name,
				genre_code : item.genre_code,
				catalog_no : item.catalog_no,
				keyword : item.keyword,
				sort : item.sort,
				boxed_num : item.boxed_num,
				url : item.url,
				pre_order_num : item.pre_order_num,
				stock_flag : item.stock_flag,
			};
			//console.log(params);

			if(Object.keys(wish_list).length){
				await deleteWishList(item.item_id);
			}
			else{
				await putWishList(params);
			}
			//throw new Error('更新しました。');
			setReload(reload+1);
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	

	const handleSubmit = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}

			const piece = Number(formObj.piece.value ?? item.minimum_unit);

			if(piece < item.minimum_unit){
				throw new Error('最少出荷単位は' + item.minimum_unit + 'です。');
			}
			
			if(piece < item.minimum_unit || piece % item.minimum_unit !== 0){
				throw new Error('数量は' + item.minimum_unit + 'の単位で指定してください。');
			}

			const params ={
				item_id : item.item_id,
				product_no : item.product_no,
				product_name : item.product_name,
				gousu : item.gousu,
				price : Number(item.price ?? 0),
				unit_price : Number(item.unit_price ?? 0),
				piece : Number(item.minimum_unit ?? 1),
				minimum_unit : item.minimum_unit,
				category1_name : item.category1_name,
				category1_code : item.category1_code,
				category2_name : item.category2_name,
				category2_code : item.category2_code,
				genre_name : item.genre_name,
				genre_code : item.genre_code,
				catalog_no : item.catalog_no,
				keyword : item.keyword,
				sort : item.sort,
				boxed_num : item.boxed_num,
				url : item.url,
				pre_order_num : item.pre_order_num,
				stock_flag : item.stock_flag,
				message : '',
			};
			//console.log(params);
			
			//更新
			const ref = await API.Cart.Add(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			setModal(['商品をカートに入れました。']);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const dateShaping = (datetime) => {
		const dateObj      = new Date(Number(datetime * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
		return(year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒');
	
	};
	
	const handleClose = () => {
		setAlert([]);
		setModal([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/admin/item/';
		}
		else{
			setReload(reload+1);
		}
	};

	const handleURL = () => {
		//window.location.href = item.url;
		window.open(item.url);
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(Object.keys(item).length === 0){
		return (
			<NotFound />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Modal show={modal.length ? true : false} onHide={handleClose} centered>
					<Modal.Body>
						{modal.map((msg, index) => {return (<p key={index} style={{margin: "0px"}}>{msg}</p>);})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClose}>
							商品選択を続ける
						</Button>
						<Button variant="primary" onClick={ () => { window.location.href = '/cart/'; } }>
							商品カートに移動する
						</Button>
					</Modal.Footer>
				</Modal>

				<Breadcrumb>
					<Breadcrumb.Item href="/item/">商品検索</Breadcrumb.Item>
					<Breadcrumb.Item active>詳細</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleWishList }>
								{ Object.keys(wish_list).length ? <FaRegStar /> : <FaStar /> }
							</Button>
							　
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'登録日時'} name={'date_add'} value={dateShaping(item.date_add)} readOnly={true} />
					<FormText label={'JANコード'} name={'item_id'} value={item.item_id} readOnly={true} />
					<FormText label={'品番'} name={'product_no'} value={item.product_no} readOnly={true} />
					<FormText label={'品名'} name={'product_name'} value={item.product_name} readOnly={true} />
					<FormText label={'号数'} name={'gousu'} value={item.gousu} readOnly={true} />
					<FormText label={'ﾒｰｶｰ希望本体価格'} name={'price'} value={Number(item?.price ?? 0).toLocaleString()} readOnly={true} />
					{/*<FormText label={'一般卸価格'} name={'unit_price'} value={Number(item?.unit_price ?? 0).toLocaleString()} readOnly={true} />*/}
					<FormText label={'最少出荷単位'} name={'minimum_unit'} value={item.minimum_unit} type={'number'} readOnly={true} />
					<FormText label={'親カテゴリー名'} name={'category1_name'} value={item.category1_name} readOnly={true} />
					<FormText label={'親カテゴリーコード'} name={'category1_code'} value={item.category1_code} readOnly={true} />
					<FormText label={'子カテゴリー名'} name={'category2_name'} value={item.category2_name} readOnly={true} />
					<FormText label={'子カテゴリーコード'} name={'category2_code'} value={item.category2_code} readOnly={true} />
					<FormText label={'ジャンル名'} name={'genre_name'} value={item.genre_name} readOnly={true} />
					<FormText label={'ジャンルコード'} name={'genre_code'} value={item.genre_code} readOnly={true} />
					<FormText label={'カタログページ'} name={'catalog_no'} value={item.catalog_no} readOnly={true} />
					<FormText label={'検索用語'} name={'keyword'} value={item.keyword} readOnly={true} />
					<FormText label={'並び順'} name={'sort'} value={item.sort} type={'number'} readOnly={true} />
					<FormText label={'内容量'} name={'boxed_num'} value={item.boxed_num} type={'number'} readOnly={true} />
					<FormText label={'URL'} name={'url'} value={item.url} readOnly={true} />
					<Row>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block="true" disabled={!item.url} onClick={handleURL}>URLに遷移する</Button>
						</Col>
					</Row>
					　
					<FormText label={'注残管理'} name={'pre_order_num'} value={item.pre_order_num} type={'number'} readOnly={true} />
					<FormText label={'在庫'} name={'stock_flag'} value={item.stock_flag} readOnly={true} />
					<hr/>
					<FormText label={'個数'} type={'number'} name={'piece'} value={item.minimum_unit} step={item.minimum_unit} required={true} />
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock}>商品カートに入れる</Button>
						</Col>
					</Row>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
