import { useState, useEffect } from 'react';
import API from './@onocomm-system';
import Amplify, { Auth } from 'aws-amplify';
import AwsExports from './@onocomm-system/include/AwsExports';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './Objects/Header';
import Footer from './Objects/Footer';
import NotFound from './Objects/NotFound';
import LoginSignOut from './Pages/Login/SignOut';
import LoginSignIn from './Pages/Login/SignIn';
import LoginSignInChangePassword from './Pages/Login/SignInChangePassword';
import LoginSignUp from './Pages/Login/SignUp';
import LoginSignUpVerify from './Pages/Login/SignUpVerify';
import LoginSignUpResent from './Pages/Login/SignUpResent';
import LoginForgotPassword from './Pages/Login/ForgotPassword';
import LoginForgotPasswordVerify from './Pages/Login/ForgotPasswordVerify';
import AccountMain from './Pages/Account/Main';
import AccountEmail from './Pages/Account/Email';
import AccountEmailVerify from './Pages/Account/EmailVerify';
import AccountPassword from './Pages/Account/Password';
import AccountUser from './Pages/Account/User';
import AdminHeader from './Pages/Admin/Header';
import AdminUserMain from './Pages/Admin/User/Main';
import AdminUserDetails from './Pages/Admin/User/Details';
import AdminUserNew from './Pages/Admin/User/New';
import AdminAdminUserMain from './Pages/Admin/AdminUser/Main';
import AdminAdminUserDetails from './Pages/Admin/AdminUser/Details';
import AdminAdminUserNew from './Pages/Admin/AdminUser/New';
import AdminItemMain from './Pages/Admin/Item/Main';
import AdminItemDetails from './Pages/Admin/Item/Details';
import AdminItemNew from './Pages/Admin/Item/New';
import AdminProductMain from './Pages/Admin/Product/Main';
import AdminProductDetails from './Pages/Admin/Product/Details';
import AdminProductNew from './Pages/Admin/Product/New';
import AdminGenreMain from './Pages/Admin/Genre/Main';
import AdminGenreDetails from './Pages/Admin/Genre/Details';
import AdminGenreNew from './Pages/Admin/Genre/New';
import AdminCategory1Main from './Pages/Admin/Category1/Main';
import AdminCategory1Details from './Pages/Admin/Category1/Details';
import AdminCategory1New from './Pages/Admin/Category1/New';
import AdminCategory2Main from './Pages/Admin/Category2/Main';
import AdminCategory2Details from './Pages/Admin/Category2/Details';
import AdminCategory2New from './Pages/Admin/Category2/New';
import AdminShipping from './Pages/Admin/Shipping/Main';
import AdminTopImages from './Pages/Admin/TopImages/Main';
import AdminOrderMain from './Pages/Admin/Order/Main';
import AdminOrderDetails from './Pages/Admin/Order/Details';
import AdminOrderNew from './Pages/Admin/Order/New';
import AdminCSVUploadMain from './Pages/Admin/CSVUpload/Main';
import AdminCSVUploadFileSelect from './Pages/Admin/CSVUpload/FileSelect';
import AdminCSVUploadCheck from './Pages/Admin/CSVUpload/Check';
import AdminCSVUploadFinalCheck from './Pages/Admin/CSVUpload/FinalCheck';
import AdminCSVUploadExecution from './Pages/Admin/CSVUpload/Execution';
import AdminCSVUploadLogDetails from './Pages/Admin/CSVUpload/LogDetails';
import AdminCSVUploadLog from './Pages/Admin/CSVUpload/Log';
import ItemMain from './Pages/Item/Main';
import ItemDetails from './Pages/Item/Details';
import ProductMain from './Pages/Product/Main';
import GenreMain from './Pages/Genre/Main';
import CategoryMain from './Pages/Category/Main';
import Category2 from './Pages/Category/Category2';
import CartMain from './Pages/Cart/Main';
import OrderMain from './Pages/Order/Main';
import OrderHistoryMain from './Pages/OrderHistory/Main';
import OrderHistoryDetails from './Pages/OrderHistory/Details';
import WishListMain from './Pages/WishList/Main';
import StockOutMain from './Pages/StockOut/Main';
import Main from './Pages/Main';

Amplify.configure(AwsExports);

const App = () => {

	const [load, setLoad] = useState(true);
	const [auth, setAuth] = useState(undefined);
	const [user, setUser] = useState(undefined);
	const [admin, setAdmin] = useState(undefined);

	useEffect(() => {
		
		//ログインチェック
		const checkAuth = async () => {

			//認証ステータス取得
			const getAuthStatus = async () => {

				try{
					//ログインユーザ名の取得
					const username = (await Auth.currentAuthenticatedUser()).username;

					if(username){
						return(true);
					}
					else{
						return(false);
					}
				}
				catch(err) {
					return(false);
				}
			};
			
			//認証ステータスの取得
			const status = await getAuthStatus();
			
			//結果記録
			if(status){
				setAuth(true);
			}
			else{
				setAuth(false);
			}
		}
		
		//ユーザ情報チェック
		const checkUser = async () => {
			
			//ユーザ情報取得
			const getUserData = async () => {
				try{
					//ユーザ情報の取得
					const ref = await API.User.Current();
					
					//console.log(ref);
					
					return(ref.item);
				}
				catch(err){
					return({});
				}
			};
			
			//ユーザ情報の取得
			const user = await getUserData();
			
			//ユーザ情報の確認
			if(Object.keys(user).length === 0){
				setUser(false);
			}
			else{
				setUser(true);
			}
			
			//管理者権限の確認
			if(Object.keys(user).length === 0){
				setAdmin(false);
			}
			else{
				if(user.admin_flag === true){
					setAdmin(true);
				}
				else{
					setAdmin(false);
				}
			}
		};
		
		//ログインチェック
		checkAuth();
		
		//ユーザ情報取得
		checkUser();
		
		//読み込み完了
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	
	//ページ判定
	if(load){
		//読み込み中
		return(<div />);
	}
	else if(auth === false){
		//ログイン前
		return (
			<BrowserRouter>
				<div>
					<Switch>
						<Route path='/signup-verify/:email' component={LoginSignUpVerify} />
						<Route path='/signup-verify' component={LoginSignUpVerify} />
						<Route path='/signup-resent' component={LoginSignUpResent} />
						<Route path='/signup' component={LoginSignUp} />
						<Route path='/forgot-password-verify/:email' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password-verify' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password' component={LoginForgotPassword} />
						<Route path='/signin-change-password/:email' component={LoginSignInChangePassword} />
						<Route component={LoginSignIn} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
	else if(auth === true && admin === true && window.location.pathname.match(/^\/admin\//) !== null){
		//ログイン後で管理者権限が管理画面パスを閲覧
		return (
			<BrowserRouter>
				<div>
					<AdminHeader/>
					<Switch>
						<Route path='/admin/csv-upload/csv-log/:csv_id' component={AdminCSVUploadLogDetails} />
						<Route path='/admin/csv-upload/csv-log' component={AdminCSVUploadLog} />
						<Route path='/admin/csv-upload/:section/execution' component={AdminCSVUploadExecution} />
						<Route path='/admin/csv-upload/:section/final-check' component={AdminCSVUploadFinalCheck} />
						<Route path='/admin/csv-upload/:section/check' component={AdminCSVUploadCheck} />
						<Route path='/admin/csv-upload/:section' component={AdminCSVUploadFileSelect} />
						<Route path='/admin/csv-upload' component={AdminCSVUploadMain} />
						<Route path='/admin/item/new' component={AdminItemNew} />
						<Route path='/admin/item/:item_id' component={AdminItemDetails} />
						<Route path='/admin/item' component={AdminItemMain} />
						<Route path='/admin/product/new' component={AdminProductNew} />
						<Route path='/admin/product/:product_no' component={AdminProductDetails} />
						<Route path='/admin/product' component={AdminProductMain} />
						<Route path='/admin/genre/new' component={AdminGenreNew} />
						<Route path='/admin/genre/:genre_code' component={AdminGenreDetails} />
						<Route path='/admin/genre' component={AdminGenreMain} />
						<Route path='/admin/category1/new' component={AdminCategory1New} />
						<Route path='/admin/category1/:category1_code' component={AdminCategory1Details} />
						<Route path='/admin/category1' component={AdminCategory1Main} />
						<Route path='/admin/category2/:category1_code/new' component={AdminCategory2New} />
						<Route path='/admin/category2/:category1_code/:category2_code' component={AdminCategory2Details} />
						<Route path='/admin/category2/:category1_code' component={AdminCategory2Main} />
						<Route path='/admin/shipping' component={AdminShipping} />
						<Route path='/admin/top-images' component={AdminTopImages} />
						<Route path='/admin/user/new' component={AdminUserNew} />
						<Route path='/admin/user/:user_id' component={AdminUserDetails} />
						<Route path='/admin/user' component={AdminUserMain} />
						<Route path='/admin/order/new' component={AdminOrderNew} />
						<Route path='/admin/order/:order_id' component={AdminOrderDetails} />
						<Route path='/admin/order' component={AdminOrderMain} />
						<Route path='/admin/admin-user/new' component={AdminAdminUserNew} />
						<Route path='/admin/admin-user/:user_id' component={AdminAdminUserDetails} />
						<Route path='/admin/admin-user' component={AdminAdminUserMain} />
						<Route path='/admin' component={Main} />
						<Route path='/account/email-verify' component={AccountEmailVerify}/>
						<Route path='/account/email' component={AccountEmail}/>
						<Route path='/account/password' component={AccountPassword}/>
						<Route path='/account' component={AccountMain}/>
						<Route path='/signout' component={LoginSignOut}/>
						<Route component={NotFound} />
					</Switch>
					<Footer/>
				</div>
			</BrowserRouter>
		);
	}
	else if(auth === true && user === false){
		//ログイン後でユーザ情報が未登録
		return (
			<BrowserRouter>
				<div>
					<Switch>
						<Route component={AccountUser} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
	else if(auth === true && user === true){
		//ログイン後
		return (
			<BrowserRouter>
				<div>
					<Header admin={admin} />
					<Switch>
						<Route exact path='/' component={Main} />
						<Route path='/account/user' component={AccountUser} />
						<Route path='/account/email-verify' component={AccountEmailVerify}/>
						<Route path='/account/email' component={AccountEmail}/>
						<Route path='/account/password' component={AccountPassword}/>
						<Route path='/account' component={AccountMain}/>
						<Route path='/cart' component={CartMain} />
						<Route path='/order-history/:order_id' component={OrderHistoryDetails} />
						<Route path='/order-history' component={OrderHistoryMain} />
						<Route path='/order' component={OrderMain} />
						<Route path='/wish-list' component={WishListMain} />
						<Route path='/stock-out' component={StockOutMain} />
						<Route path='/signout' component={LoginSignOut}/>
						<Route path='/:flag/:target/:sub_target' component={ItemMain} />
						<Route path='/category/:category1_code' component={Category2} />
						<Route path='/item/:item_id' component={ItemDetails} />
						<Route path='/:flag/:target' component={ItemMain} />
						<Route path='/category' component={CategoryMain} />
						<Route path='/item' component={ItemMain} />
						<Route path='/product' component={ProductMain} />
						<Route path='/genre' component={GenreMain} />
						<Route component={NotFound} />
					</Switch>
					<Footer/>
				</div>
			</BrowserRouter>
		);
	}
	else{
		//未定義
		return(<div />);
	}
};

export default App;

