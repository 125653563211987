const Default = 'ver.3.16';

//VVer3.8 2023-11-20 在庫表示を○×表記に変更
//Ver.3.9 2023-12-21 トップ画像を追加
//Ver.3.10 2023-12-27 ユーザ情報の更新エラーを修正
//Ver.3.11 2024-1-9 トップ画像を変更
//Ver.3.12 2024-1-15 unit_priceを小数点対応に変更
//Ver.3.13 2024-1-26 受注画面の詳細表示において、合計金額が正しく計算されない不具合を修正
//Ver.3.14 トップページの画像アップロード機能を追加
//Ver.3.15 CSVログの表示を変更（期限切れのものは非表示）
//Ver.3.16 管理画面以外の画面よりunit_price（一般卸価格）を非表示に変更

export default Default;

