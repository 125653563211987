import { useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { FormSelect } from '../../../Objects/Form';


const Default = () => {

	const history = useHistory();
	
	useEffect(() => {
		
		//ストレージ
		const storage = sessionStorage;
		
		//データ削除
		storage.removeItem('csv_data');
		storage.removeItem('csv_column_data');
		storage.removeItem('csv_select_ids');
		storage.removeItem('csv_final_check');
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleSbumit = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formオブジェクトの取得
		const formObj = event.currentTarget;
		
		if(formObj.section.value === '商品情報'){
			history.push('/admin/csv-upload/item/');
		}
		else if(formObj.section.value === '品番情報'){
			history.push('/admin/csv-upload/product/');
		}
		else if(formObj.section.value === 'ジャンル情報'){
			history.push('/admin/csv-upload/genre/');
		}
		else if(formObj.section.value === '親カテゴリ情報'){
			history.push('/admin/csv-upload/category1/');
		}
		else if(formObj.section.value === '子カテゴリ情報'){
			history.push('/admin/csv-upload/category2/');
		}
	};
	
	return (
		<Container style={{ marginTop: 50, marginBottom: 200 }}>
			<Row>
				<Col sm={6}>
					<p><big>CSVアップロード</big></p>
				</Col>
			</Row>
			<hr/>
			<p>
				アップロードされたいCSVの種別を選んでください。
			</p>

			<Form onSubmit={handleSbumit}>
				<FormSelect label={'CSV種別'} name={'section'} value={'商品情報'} data={ [ '商品情報', '品番情報', 'ジャンル情報', '親カテゴリ情報', '子カテゴリ情報'] } required={true} />
				<hr/>
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="dark" type="submit" block="true" >選択</Button>
							　
					        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} >もどる</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default withRouter(Default);
