
const CSVUploadDefine = {
	item : [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '数値',
			csvRequired: true,
			csvMemo: '値が存在しない場合は0を指定してください',
		},
		{
			dataField: 'unit_price',
			text: '一般卸価格',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '数値',
			csvRequired: true,
			csvMemo: '値が存在しない場合は0を指定してください',
		},
		{
			dataField: 'minimum_unit',
			text: '最少出荷単位',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '数値',
			csvRequired: true,
			csvMemo: '値が存在しない場合は0を指定してください',
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category2_name',
			text: '子カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category2_code',
			text: '子カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'genre_name',
			text: 'ジャンル名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'genre_code',
			text: 'ジャンルコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'catalog_no',
			text: 'カタログページ',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
		},
		{
			dataField: 'keyword',
			text: '検索用語',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
		},
		{
			dataField: 'sort',
			text: '並び順',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '数値',
			csvRequired: true,
			csvMemo: '値が存在しない場合は0を指定してください',
		},
		{
			dataField: 'boxed_num',
			text: '内容量',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '数値',
			csvRequired: true,
			csvMemo: '値が存在しない場合は0を指定してください',
		},
		{
			dataField: 'url',
			text: 'URL',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
		},
		{
			dataField: 'pre_order_num',
			text: '注残管理注残管理',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '数値',
			csvRequired: true,
			csvMemo: '値が存在しない場合は0を指定してください',
		},
		{
			dataField: 'stock_flag',
			text: '在庫',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '"○"か"×"で指定してください',
		},
	],
	product : [
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
	],
	genre : [
		{
			dataField: 'genre_code',
			text: 'ジャンルコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'genre_name',
			text: 'ジャンル名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
	],
	category1 : [
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
	],
	category2: [
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category2_code',
			text: '子カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
		{
			dataField: 'category2_name',
			text: '子カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			csvType: '文字',
			csvRequired: true,
			csvMemo: '必ず指定してください',
		},
	],
};


export default CSVUploadDefine;
