import { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Breadcrumb,
	Badge,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import Table from '../../../Objects/Table';
import { ModalAlert } from '../../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { section } = useParams();
	const [load, setLoad] = useState(true);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [csv_data, setCsvData] = useState([]);
	const [view, setView] = useState([]);
	const [column_data, setColumnData] = useState([]);
	
	useEffect(() => {
	
		//ストレージ
		const storage = sessionStorage;
		
		//CSVデータ
		const csvData = storage.getItem('csv_data') ? JSON.parse(storage.getItem('csv_data')) : [];

		//Columnデータ
		const columnData = storage.getItem('csv_column_data') ? JSON.parse(storage.getItem('csv_column_data')) : [];
		
		//セレクトIDデータ
		const selectIDs = storage.getItem('csv_select_ids') ? JSON.parse(storage.getItem('csv_select_ids')) : [];
		
		//新しいデータ
		const newCSVData = [];
		
		//チェックされたデータだけを抜き出し
		for(const item of csvData){
			if(selectIDs.length){
				for(const id of selectIDs){
					if(item[columnData[0].dataField] === id){
						newCSVData.push(item);
					}
				}
			}
			else{
				newCSVData.push(item);
			}
		}
		
		//保存
		setView(newCSVData);
		setCsvData(newCSVData);
		setColumnData(columnData);
		
		//表示開始
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			//ストレージ
			const storage = sessionStorage;
			
			//データ登録
			storage.setItem('csv_final_check', JSON.stringify(true));
			
			
			//ページ遷移
			window.location.href = '/admin/csv-upload/' + section + '/execution/';
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};

	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = csv_data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleClose = () => {
		setAlert([]);
		//window.location.reload();
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(!section || column_data.length === 0){
		return (
			<ModalAlert data={['CSV種別が不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else if(csv_data.length === 0){
		return (
			<ModalAlert data={['CSVデータが不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/csv-upload/">CSVアップロード</Breadcrumb.Item>
					<Breadcrumb.Item href={'/admin/csv-upload/' + section + '/'}>CSVファイルの選択</Breadcrumb.Item>
					<Breadcrumb.Item href={'/admin/csv-upload/' + section + '/check/'}>アップロード内容の確認</Breadcrumb.Item>
					<Breadcrumb.Item active>最終確認</Breadcrumb.Item>
				</Breadcrumb>

				<Row>
					<Col sm={6}>
						<p><big>最終確認</big></p>
					</Col>
				</Row>
				<hr/>
				<p>
					※データに問題がないか確認をしてください。<br/>
					※検索結果にかかわらずアップロードは{csv_data.length.toLocaleString()}件のデータが対象となります。<br/>
				</p>
				<Form onSubmit={handleSearch}>
					<Row style={{ marginTop: 20 }}>
						<Col sm={2} className="text-center">
							<Badge pill variant="secondary">
								<h6>　{ csv_data.length ? csv_data.length.toLocaleString() : '0' }　</h6>
							</Badge>　
						</Col>
						<Col sm={5}>
							<Form.Group controlId="search_key">
							        <Form.Control name="search_key" type="text" placeholder="Search" />
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Button variant="dark" type="submit" block="true" >Search</Button>
						</Col>
					</Row>
				</Form>
				<Row style={{ marginTop: 20 }}>
					<Col sm={12}>
						<Table keyField={column_data[0].dataField} data={view} columns={column_data} />
					</Col>
				</Row>
				<hr/>
				<Form onSubmit={handleSubmit}>
					<Row style={{ marginTop: 10 }}>
						<Col>
							<Button variant="dark" type="submit" block="true" >アップロード実行</Button>
							　
							<Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
