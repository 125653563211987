
const GraphQL = {
	getUserCurrent :	`query getUserCurrent {
				getUserCurrent {
					user_id
					email
					representative_email
					user_no
					user_name
					zip
					addr
					company
					person
					tel
					fax
					mobile
					admin_flag
					status
					date_add
					date_edit
				}
			}`,
	getUser :	`query getUser($user_id: String!) {
				getUser(user_id: $user_id) {
					user_id
					email
					representative_email
					user_no
					user_name
					zip
					addr
					company
					person
					tel
					fax
					mobile
					admin_flag
					status
					date_add
					date_edit
				}
			}`,
	scanUser : 	`query scanUser($limit: Int, $nextToken: String) {
					scanUser(limit: $limit, nextToken: $nextToken) {
						items {
							user_id
							email
							representative_email
							user_no
							user_name
							zip
							addr
							company
							person
							tel
							fax
							mobile
							admin_flag
							status
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	putUser :	`mutation putUser($data: UserInput!) {
				putUser(input: $data) {
					user_id
				}
			}`,
	updateUser :	`mutation updateUser($data: UserInput!) {
				updateUser(input: $data) {
					user_id
				}
			}`,
	executeUserOperation : `mutation executeUserOperation(
						$flag: String!,
						$data: UserInput!
					) {
					executeUserOperation(
						flag: $flag,
						data: $data
					) {
						result
						error
					}
				}`,
	executeUpload : `mutation executeUpload(
						$file: String!,
						$file_name: String
					) {
					executeUpload(
						file: $file,
						file_name: $file_name
					) {
						result
						error
					}
				}`,
	getItem :	`query getItem($item_id: String!) {
				getItem(item_id: $item_id) {
					item_id
					product_no
					product_name
					gousu
					price
					unit_price
					minimum_unit
					category1_name
					category1_code
					category2_name
					category2_code
					genre_name
					genre_code
					catalog_no
					keyword
					sort
					boxed_num
					url
					pre_order_num
					stock_flag
					date_add
					date_edit				}
			}`,
	scanItem : 	`query scanItem($limit: Int, $nextToken: String) {
					scanItem(limit: $limit, nextToken: $nextToken) {
						items {
							item_id
							product_no
							product_name
							gousu
							price
							unit_price
							minimum_unit
							category1_name
							category1_code
							category2_name
							category2_code
							genre_name
							genre_code
							catalog_no
							keyword
							sort
							boxed_num
							url
							pre_order_num
							stock_flag
							date_add
							date_edit
						}
						nextToken
					}
				}`,	
	queryItemProduct : 	`query queryItemProduct($product_no: String, $limit: Int, $nextToken: String) {
					queryItemProduct(product_no: $product_no, limit: $limit, nextToken: $nextToken) {
						items {
							item_id
							product_no
							product_name
							gousu
							price
							unit_price
							minimum_unit
							category1_name
							category1_code
							category2_name
							category2_code
							genre_name
							genre_code
							catalog_no
							keyword
							sort
							boxed_num
							url
							pre_order_num
							stock_flag
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	queryItemGenre : 	`query queryItemGenre($genre_code: String, $limit: Int, $nextToken: String) {
					queryItemGenre(genre_code: $genre_code, limit: $limit, nextToken: $nextToken) {
						items {
							item_id
							product_no
							product_name
							gousu
							price
							unit_price
							minimum_unit
							category1_name
							category1_code
							category2_name
							category2_code
							genre_name
							genre_code
							catalog_no
							keyword
							sort
							boxed_num
							url
							pre_order_num
							stock_flag
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	queryItemCategory : 	`query queryItemCategory($category1_code: String, $category2_code: String, $limit: Int, $nextToken: String) {
					queryItemCategory(category1_code: $category1_code, category2_code: $category2_code, limit: $limit, nextToken: $nextToken) {
						items {
							item_id
							product_no
							product_name
							gousu
							price
							unit_price
							minimum_unit
							category1_name
							category1_code
							category2_name
							category2_code
							genre_name
							genre_code
							catalog_no
							keyword
							sort
							boxed_num
							url
							pre_order_num
							stock_flag
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	putItem :	`mutation putItem($data: ItemInput!) {
				putItem(input: $data) {
					item_id
				}
			}`,
	updateItem :	`mutation updateItem($data: ItemInput!) {
				updateItem(input: $data) {
					item_id
				}
			}`,
	deleteItem :	`mutation deleteItem($item_id: String!) {
				deleteItem(item_id: $item_id) {
					item_id
				}
			}`,
	getProduct :	`query getProduct($product_no: String!) {
				getProduct(product_no: $product_no) {
					product_no
					product_name
					date_add
					date_edit
				}
			}`,
	scanProduct : 	`query scanProduct($limit: Int, $nextToken: String) {
				scanProduct(limit: $limit, nextToken: $nextToken) {
					items {
						product_no
						product_name
						date_add
						date_edit
					}
					nextToken
				}
			}`,
	putProduct :	`mutation putProduct($data: ProductInput!) {
				putProduct(input: $data) {
					product_no
				}
			}`,
	updateProduct :	`mutation updateProduct($data: ProductInput!) {
				updateProduct(input: $data) {
					product_no
				}
			}`,
	deleteProduct :	`mutation deleteProduct($product_no: String!) {
				deleteProduct(product_no: $product_no) {
					product_no
				}
			}`,
	getGenre :	`query getGenre($genre_code: String!) {
				getGenre(genre_code: $genre_code) {
					genre_code
					genre_name
					date_add
					date_edit
				}
			}`,
	scanGenre : 	`query scanGenre($limit: Int, $nextToken: String) {
				scanGenre(limit: $limit, nextToken: $nextToken) {
					items {
						genre_code
						genre_name
						date_add
						date_edit
					}
					nextToken
				}
			}`,
	putGenre :	`mutation putGenre($data: GenreInput!) {
				putGenre(input: $data) {
					genre_code
				}
			}`,
	updateGenre :	`mutation updateGenre($data: GenreInput!) {
				updateGenre(input: $data) {
					genre_code
				}
			}`,
	deleteGenre :	`mutation deleteGenre($genre_code: String!) {
				deleteGenre(genre_code: $genre_code) {
					genre_code
				}
			}`,
	getCategory1 :	`query getCategory1($category1_code: String!) {
				getCategory1(category1_code: $category1_code) {
					category1_code
					category1_name
					date_add
					date_edit
				}
			}`,
	scanCategory1 : 	`query scanCategory1($limit: Int, $nextToken: String) {
				scanCategory1(limit: $limit, nextToken: $nextToken) {
					items {
						category1_code
						category1_name
						date_add
						date_edit
					}
					nextToken
				}
			}`,
	putCategory1 :	`mutation putCategory1($data: Category1Input!) {
				putCategory1(input: $data) {
					category1_code
				}
			}`,
	updateCategory1 :	`mutation updateCategory1($data: Category1Input!) {
				updateCategory1(input: $data) {
					category1_code
				}
			}`,
	deleteCategory1 :	`mutation deleteCategory1($category1_code: String!) {
				deleteCategory1(category1_code: $category1_code) {
					category1_code
				}
			}`,
	getCategory2 :	`query getCategory2($category1_code: String!, $category2_code: String!) {
				getCategory2(category1_code: $category1_code, category2_code: $category2_code) {
					category1_code
					category1_name
					category2_code
					category2_name
					date_add
					date_edit
				}
			}`,
	queryCategory2 :	`query queryCategory2($category1_code: String!, $limit: Int, $nextToken: String) {
				queryCategory2(category1_code: $category1_code, limit: $limit, nextToken: $nextToken) {
					items {
						category1_code
						category1_name
						category2_code
						category2_name
						date_add
						date_edit
					}
					nextToken
				}
			}`,
	putCategory2:	`mutation putCategory2($data: Category2Input!) {
				putCategory2(input: $data) {
					category1_code
					category2_code
				}
			}`,
	updateCategory2 :	`mutation updateCategory2($data: Category2Input!) {
				updateCategory2(input: $data) {
					category1_code
					category2_code
				}
			}`,
	deleteCategory2 :	`mutation deleteCategory2($category1_code: String!, $category2_code: String!) {
				deleteCategory2(category1_code: $category1_code, category2_code: $category2_code) {
					category1_code
					category2_code
				}
			}`,
	queryCSVLog :	`query queryCSVLog($csv_id: String!, $limit: Int, $nextToken: String) {
				queryCSVLog(csv_id: $csv_id, limit: $limit, nextToken: $nextToken) {
					items {
						csv_id
						num
						target_section
						target_id_name
						target_id_value
						target_text
						execute_flag
						result_flag
						message
						date_add
					}
					nextToken
				}
			}`,
	scanCSVLog :	`query scanCSVLog($limit: Int, $nextToken: String) {
				scanCSVLog(limit: $limit, nextToken: $nextToken) {
					items {
						csv_id
						num
						target_section
						target_id_name
						target_id_value
						target_text
						execute_flag
						result_flag
						message
						date_add
					}
					nextToken
				}
			}`,
	putCSVLog :	`mutation putCSVLog($data: CSVLogInput!) {
				putCSVLog(input: $data) {
					csv_id
				}
			}`,
	getCart :	`query getCart($item_id: String!) {
				getCart(item_id: $item_id) {
					item_id
					product_no
					product_name
					gousu
					price
					unit_price
					minimum_unit
					category1_name
					category1_code
					category2_name
					category2_code
					genre_name
					genre_code
					catalog_no
					keyword
					sort
					boxed_num
					url
					pre_order_num
					stock_flag
					date_add
					date_edit
					message
					piece
				  
				}
			}`,
	queryCart :	`query queryCart($limit: Int, $nextToken: String) {
				queryCart(limit: $limit, nextToken: $nextToken) {
					items {
						item_id
						product_no
						product_name
						gousu
						price
						unit_price
						minimum_unit
						category1_name
						category1_code
						category2_name
						category2_code
						genre_name
						genre_code
						catalog_no
						keyword
						sort
						boxed_num
						url
						pre_order_num
						stock_flag
						date_add
						date_edit
						message
						piece
						}
					nextToken
				}
			}`,
	putCart :	`mutation putCart($data: CartInput!) {
				putCart(input: $data) {
					item_id
				}
			}`,
	updateCart :	`mutation updateCart($data: CartInput!) {
				updateCart(input: $data) {
					item_id
				}
			}`,
	deleteCart :	`mutation deleteCart($item_id: String!) {
				deleteCart(item_id: $item_id) {
					item_id
				}
			}`,
	getWishList :	`query getWishList($item_id: String!) {
				getWishList(item_id: $item_id) {
					item_id
					product_no
					product_name
					gousu
					price
					unit_price
					minimum_unit
					category1_name
					category1_code
					category2_name
					category2_code
					genre_name
					genre_code
					catalog_no
					keyword
					sort
					boxed_num
					url
					pre_order_num
					stock_flag
					date_add
					date_edit				}
			}`,
	queryWishList :	`query queryWishList($limit: Int, $nextToken: String) {
				queryWishList(limit: $limit, nextToken: $nextToken) {
					items {
						item_id
						product_no
						product_name
						gousu
						price
						unit_price
						minimum_unit
						category1_name
						category1_code
						category2_name
						category2_code
						genre_name
						genre_code
						catalog_no
						keyword
						sort
						boxed_num
						url
						pre_order_num
						stock_flag
						date_add
						date_edit
					}
					nextToken
				}
			}`,
	putWishList :	`mutation putWishList($data: WishListInput!) {
				putWishList(input: $data) {
					item_id
				}
			}`,
	updateWishList :	`mutation updateWishList($data: WishListInput!) {
				updateWishList(input: $data) {
					item_id
				}
			}`,
	deleteWishList :	`mutation deleteWishList($item_id: String!) {
				deleteWishList(item_id: $item_id) {
					item_id
				}
			}`,
	getOrder :	`query getOrder($order_id: String!) {
				getOrder(order_id: $order_id) {
					order_id
					user_id
					email
					representative_email
					user_no
					user_name
					zip
					addr
					company
					person
					tel
					fax
					mobile
					note
					date_add
					date_edit
					status
					shipping
					total_price
					items {
						item_id
						product_no
						product_name
						gousu
						price
						unit_price
						minimum_unit
						category1_name
						category1_code
						category2_name
						category2_code
						genre_name
						genre_code
						catalog_no
						keyword
						sort
						boxed_num
						url
						pre_order_num
						stock_flag
						date_add
						date_edit
						message
						piece	
					}
				}
			}`,
	queryOrder :	`query queryOrder($limit: Int, $nextToken: String, $order_id: String, $count: Boolean) {
				queryOrder(limit: $limit, nextToken: $nextToken, order_id: $order_id, count: $count) {
					items {
						order_id
						user_id
						email
						representative_email
						user_no
						user_name
						zip
						addr
						company
						person
						tel
						fax
						mobile
						note
						date_add
						date_edit
						status
						shipping
						total_price
						items {
							item_id
							product_no
							product_name
							gousu
							price
							unit_price
							minimum_unit
							category1_name
							category1_code
							category2_name
							category2_code
							genre_name
							genre_code
							catalog_no
							keyword
							sort
							boxed_num
							url
							pre_order_num
							stock_flag
							date_add
							date_edit
							message
							piece
							sub_price		
						}
					}
					nextToken
				}
			}`,
	scanOrder :	`query scanOrder($limit: Int, $nextToken: String, $count: Boolean) {
				scanOrder(limit: $limit, nextToken: $nextToken, count: $count) {
					items {
						order_id
						user_id
						email
						representative_email
						user_no
						user_name
						zip
						addr
						company
						person
						tel
						fax
						mobile
						note
						date_add
						date_edit
						status
						shipping
						total_price
						items {
							item_id
							product_no
							product_name
							gousu
							price
							unit_price
							minimum_unit
							category1_name
							category1_code
							category2_name
							category2_code
							genre_name
							genre_code
							catalog_no
							keyword
							sort
							boxed_num
							url
							pre_order_num
							stock_flag
							date_add
							date_edit
							message
							piece
							sub_price		
						}
					}
					nextToken
				}
			}`,
	putOrder :	`mutation putOrder($data: OrderInput!) {
				putOrder(input: $data) {
					order_id
				}
			}`,
	updateOrder :	`mutation updateOrder($data: OrderInput!) {
				updateOrder(input: $data) {
					order_id
				}
			}`,
	scanParam :	`query scanParam($limit: Int, $nextToken: String) {
				scanParam(limit: $limit, nextToken: $nextToken) {
					items {
						name
						value
					}
					nextToken
				}
			}`,
	updateParam :	`mutation updateParam($data: ParamInput!) {
				updateParam(input: $data) {
					name
					value
				}
			}`,
	};
export default GraphQL;
