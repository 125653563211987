import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import { Decimal } from 'decimal.js';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [total_price, setTotalPrice] = useState(0);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Cart.Query();
				
				//データセット
				setData(ref.items);

				//合計
				let total_price = new Decimal(0);
	
				for(const item of ref.items){
					const sub_price = new Decimal(item.price).times(item.piece);
					total_price = total_price.plus(sub_price);
				}
				
				//保存
				setTotalPrice(total_price.toNumber());
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleDelete = async (item_id) => {

		//Formをロック
		setLock(true);
		
		try {
			//削除
			const ref = await API.Cart.Delete(item_id);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		setReload(reload+1);
	};
	
	const handlePieceChange = async (item_id, piece, minimum_unit) => {

		//Formをロック
		//setLock(true);
		
		try {

			if(Number(piece) < Number(minimum_unit)){
				piece = minimum_unit;
			}				

			//更新
			const params ={
				item_id : item_id,
				piece : Number(piece),
			};

			//更新
			const ref = await API.Cart.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//throw new Error('更新しました。');
			setReload(reload+1);
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		//setLock(false);
	};
	
	const formatItemLink = (cell, row) => {
		return (
			<div>
				<a href={'/item/' + row.item_id}>{cell}</a>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatPiece = (cell, row) => {
		return (
			<div className="text-right">
				<a name={'#' + row.item_id} href={'#' + row.item_id} >
					{Number(cell ?? 0).toLocaleString()} 個
				</a>
				　[ <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell + row.minimum_unit), row.minimum_unit)} disabled={lock}>＋</a> | <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell - row.minimum_unit), row.minimum_unit)} disabled={lock}>－</a> ]
			</div>
		);
	};
	
	const formatSubPrice = (cell, row) => {
		const sub_price = Number(row.price) * Number(row.piece);
		return (
			<div className="text-right">
				{Number(sub_price ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatDelete = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
					削除
				</Button>
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
/*
		{
			dataField: 'unit_price',
			text: '一般卸価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
*/
		{
			dataField: 'minimum_unit',
			text: '最少出荷単位',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category2_name',
			text: '子カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category2_code',
			text: '子カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'genre_name',
			text: 'ジャンル名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'genre_code',
			text: 'ジャンルコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'catalog_no',
			text: 'カタログページ',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'keyword',
			text: '検索用語',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'sort',
			text: '並び順',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'boxed_num',
			text: '内容量',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'url',
			text: 'URL',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'pre_order_num',
			text: '注残管理',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'stock_flag',
			text: '在庫',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'piece',
			text: '個数',
			sort: true,
			headerClasses: 'text-right',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatPiece,
		},
		{
			dataField: 'dummy1',
			text: '合計金額',
			sort: true,
			headerClasses: 'text-right',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatSubPrice,
		},
		{
			dataField: 'message',
			text: '備考',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy2',
			text: '削除',
			sort: false,
			headerClasses: 'text-center',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatDelete,
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				<Row>
					<Col sm={12}>
						<h4>商品カート</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form>
							<Row style={{ marginTop: 20 }}>
								<Col sm={2}>
									<Badge pill variant="secondary">
										<h6>　合計 { Number(total_price ?? 0).toLocaleString() } 円　</h6>
									</Badge>
								</Col>
								<Col sm={10} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
									<br/>
									　
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Form name="form">
							<Button variant="dark" type="button" block="true" onClick={() => { window.location.href='/order/'; } } disabled={lock} >発注手続きへ</Button>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'item_id'} data={data} columns={columns} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
