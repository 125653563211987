import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../../Objects/Table';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import CSVDownload from '../../../Objects/CSVDownload';


const Default = () => {

	const history = useHistory();
	const { category1_code } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [csv, setCSV] = useState([]);
	const [csvName, setCsvName] = useState(null);
	const [selectIDs, setSelectIDs] = useState([]);

	useEffect(() => {
	
		const getData = async (category1_code) => {
			
			try{
				//件数の取得
				const ref = await API.Category2.Query(category1_code);
				
				//データセット
				setData(ref.items);
				setView(ref.items);
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData(category1_code);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	
	const handleCreateCSV = () => {
	
		//対象データの抽出
		const csvData = data.filter(x => selectIDs.includes(x.category2_code));

		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName('category2_' + dateText + '.csv');

		//CSVデータのセット
		setCSV(csvData);
	};
	
	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/admin/category2/' + category1_code + '/' + row.category2_code)}>
					詳細
				</Button>
			</div>
		);
	};
	
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};



	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category2_code',
			text: '子カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category2_name',
			text: '子カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'date_add',
			text: '登録日時',
			sort: true,
			formatter: formatDate,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h4>子カテゴリー情報</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={2}>
									<Button variant="danger" type="button" block="true" onClick={() => history.push('/admin/category2/' + category1_code + '/new/')} >新規登録</Button>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="button" block="true" onClick={() => history.push('/admin/csv-upload/category2/')} >CSVアップロード</Button>
								</Col>
							</Row>

							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'category2_code'} data={view} columns={columns} checkbox={true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} />
					</Col>
				</Row>
				<CSVDownload data={csv}	filename={csvName} onClick={handleCreateCSV} >
					CSV出力
				</CSVDownload>
			</Container>
		);
	}
};

export default withRouter(Default);
