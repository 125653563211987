import { useState, useEffect  } from 'react';
import {
	Modal,
	Row,
	Col,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import Table from '../../../Objects/Table';


const Default = (props) => {

	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
			setData(props.data);
			setView(props.data);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	const handleClose = () => {
		setShow(false);
	};
	
	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => props.onSelect(row)}>
					選択
				</Button>
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'user_id',
			isDummyField: true,
			text: '選択',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'user_no',
			text: '得意先コード',
			sort: true,
		},
		{
			dataField: 'user_name',
			text: '得意先名',
			sort: true,
		},
		{
			dataField: 'person',
			text: '担当者名',
			sort: true,
		},
		{
			dataField: 'email',
			text: 'メールアドレス',
			sort: true,
		},
		{
			dataField: 'status',
			text: 'ステータス',
			sort: true,
		},
	];

	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	return (
		<Modal show={show} onHide={handleClose} size="lg">
			<Modal.Body>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={2} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={2}>
									<Button variant="secondary" type="button" block="true" onClick={handleClose} >Close</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Table keyField={'user_id'} data={view} columns={columns} checkbox={false} />
			</Modal.Body>
		</Modal>
	);
};

export default Default;

