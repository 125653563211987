import {
	Navbar,
	Nav,
	NavDropdown,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import Version from '../../Objects/Version';

const Default = () => {

	const history = useHistory();

	const handleClick = (uri) => {
		if(uri === '/'){
			window.location.href = '/';
		}
		else{
			history.push(uri);
		}
	};
	return (
		<div>
			<Navbar collapseOnSelect bg="dark" variant="dark" className="no_print">
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav onSelect={handleClick} className="mr-auto">
						<Nav.Link eventKey={"/admin/order/"}>受注情報</Nav.Link>
						<Nav.Link eventKey={"/admin/item/"}>商品情報</Nav.Link>
						<Nav.Link eventKey={"/admin/user/"}>ユーザ情報</Nav.Link>
						<Nav.Link eventKey={"/admin/admin-user/"}>管理者情報</Nav.Link>
						<NavDropdown title="マスタ情報" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/admin/product/"}>品番マスタ</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/admin/genre/"}>ジャンルマスタ</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/admin/category1/"}>カテゴリマスタ</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/admin/shipping/"}>送料</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/admin/top-images/"}>トップ画像</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="CSVアップロード" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/admin/csv-upload/"}>CSVアップロード</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/admin/csv-upload/csv-log/"}>CSVログ情報</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					<Nav onSelect={handleClick}>
						<NavDropdown title="ログイン情報" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/account/"}>アカウント情報</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/"}>発注画面</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/signout/"}>ログオフ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/"} style={{color:'white'}}>{Version}</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default withRouter(Default);

